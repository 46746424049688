import React from "react";
import "./CircularProgress.css";

export default function CircularProgress({progress, total}) {
  const radius = 10;
  const circumference = 2 * Math.PI * radius;
  const percentage = total > 0 ? (100 * progress) / total : 0;
  const progressValue = ((100 - percentage) / 100) * circumference;
 
  return (
    <svg height="32" width="32" className="progress-circle">
      <circle
        className="progress-circle__background"
        strokeWidth="4"
        fill="transparent"
        r={radius}
        cx="16"
        cy="16"
      />
      <circle
        className="progress-circle__circle"
        strokeWidth="4"
        fill="transparent"
        r={radius}
        cx="16"
        cy="16"
        style={{
          strokeDasharray: circumference,
          strokeDashoffset: progressValue,
        }}
      />
    </svg>
  );
};
