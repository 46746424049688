import React from "react";

const UploadFile = React.forwardRef(({ onUploadedFile, type, multipleSelect = true, children }, ref) => {
  const onClickHandler = () => {
    ref.current.click();
  };

  const onChange = (e) => {
    e.preventDefault();
    e.stopPropagation();
    if (e.target.files && e.target.files[0]) {
      onUploadedFile(e.target.files);
    }
    e.target.value = null // clearing input
  };

  const onDrop = (e) => {
    e.preventDefault();
    e.stopPropagation();
    if (e.dataTransfer.files && e.dataTransfer.files[0]) {
      onUploadedFile(e.dataTransfer.files);
    }
  };

  const handleDrag = function (e) {
    e.preventDefault();
    e.stopPropagation();
  };

  return (
    <div
      onClick={onClickHandler}
      onDragEnter={handleDrag}
      onDragLeave={handleDrag}
      onDragOver={handleDrag}
      onDrop={onDrop}
    >
      <input ref={ref} type="file" multiple={multipleSelect} onChange={onChange} accept={type} className="hidden" />
      {children}
    </div>
  );
});

export default UploadFile;
