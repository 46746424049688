export default function CustomButton({ text, onClick, styles, children, onMouseEnter, onMouseLeave }) {
  return (
    <div
      className={"w-full flex justify-center items-center text-white bg-primaryBlue py-[17px] text-standardXLarge cursor-pointer select-none " + (styles ? styles : "")}
      onClick={onClick}
      onMouseEnter={onMouseEnter}
      onMouseLeave={onMouseLeave}
    >
      {text}
      {children}
    </div>
  );
}
