import React, { useState } from "react";

export const overlayContext = React.createContext({});

export const OverlayProvider = ({ children }) => {
    const [overlay, setOverlay] = useState({show: false, content: <></>})

    return (
      <overlayContext.Provider
        value={{
         overlay,
         setOverlay
        }}
      >
        {children}
      </overlayContext.Provider>
    );
  };
  