import { v4 as uuid } from "uuid";

export const getUuid = () => {
	return uuid();
};

export const fileToBase64 = (file, callback, type = "application/pdf") => {
	const reader = new FileReader();
	reader.readAsDataURL(file);
	reader.onload = function () {
		const removedPrefix = reader.result.replace(`data:${type};base64,`, "");
		callback(null, removedPrefix);
	};
	reader.onerror = function (error) {
		callback(error, null);
	};
};

export const downloadPDFFile = (href) => {
	const downloadLink = document.createElement("a");
	downloadLink.href = href;
	downloadLink.target = "_blank";
	downloadLink.click();
};

export const getUTCDateTime = (date) => {
	const localTimezoneOffset = date.getTimezoneOffset() * 60000; // get the time zone offset in milliseconds
	const utcTime = date.getTime() - localTimezoneOffset; // subtract the offset to get the UTC time

	// create a new Date object with the UTC time
	const utcDate = new Date(utcTime);
	return utcDate;
};
