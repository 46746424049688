import Popup from "__utilities/components/Popup";
import React from "react";

export default function DownloadPopup({ onDownload }) {
  return (
    <Popup>
      <img className="content-tickCircleIcon" alt="" />
      <div className="text-primaryBlue font-latoBold">
        Din ekstrakt er færdig.
      </div>
      <div className="h-[1px] bg-[#F0F3F5] my-[20px]" />
      <div
        onClick={onDownload}
        className="cursor-pointer underline text-[#C58B68] flex"
      >
        Klik her for at downloade
        <img className="content-downloadIcon" alt="" />
      </div>
    </Popup>
  );
}
