import { downloadPDFFile, getUTCDateTime } from "__utilities/functions/public";
import React, { useContext, useState } from "react";
import {
	editExtractedFileRequest,
	extractDataFromPDFRequest,
	getHeadersRequest,
	validatePdfFileRequest,
	getLogoPositionsRequest,
	margeDocumentsRequest,
} from "./service";
import { overlayContext } from "../overlay";
import DownloadPopup from "_features/DownloadPopup";
import LoadingPopup from "_features/LoadingPopup";
import ErrorPopup from "_features/ErrorPopup";
import { baseUrl } from "_config";
import { useEffect } from "react";
import useFormValidation from "./hooks/useFormValidation";

export const extractContext = React.createContext({});

export const ExtractProvider = ({ children }) => {
	const [selectedCourt, setSelectedCourt] = useState("City");

	const [selectedImage, setSelectedImage] = useState("");

	const [selectedStartDate, setSelectedStartDate] = useState();

	const [courtNumber, setCourtNumber] = useState("");
	const [nameOfPlaintiff, setNameOfPlaintiff] = useState("");
	const [cvrOfPlaintiff, setCvrOfPlaintiff] = useState("");
	const [addressOfPlaintiff, setAddressOfPlaintiff] = useState("");
	const [lawyerOfPlaintiff, setLawyerOfPlaintiff] = useState("");
	const [nameOfDefendant, setNameOfDefendant] = useState("");
	const [addressOfDefendant, setAddressOfDefendant] = useState("");
	const [lawyerOfDefendant, setLawyerOfDefendant] = useState("");
	const [cvrOfDefendant, setCvrOfDefendant] = useState("");
	const [highCourtText, setHighCourtText] = useState("");

	const [printBothSide, setPrintBothSide] = useState(false);

	const [addTimeline, setAddTimeline] = useState(false);

	const [selectedLogoPosition, setSelectedLogoPosition] = useState(false);

	const [logoPositions, setLogoPositions] = useState([]);

	const [blockTypeSwitch, setBlockTypeSwitch] = useState(false);

	const { setOverlay } = useContext(overlayContext); // { show: true, content: <div></div> }

	const [extractedData, setExtractedData] = useState(null);

	const [extractId, setExtractId] = useState(null);

	const [editKey, setEditKey] = useState(null);

	const [path, setPath] = useState(null);

	const chunkSize = 10 * 1024 * 1024;
	const [currentChunkIndex, setCurrentChunkIndex] = useState(null);
	const [processing, setProcessing] = useState(null);
	const [uploadFile, setUploadFile] = useState();
	const [uploadSuccess, setUploadSuccess] = useState(null);
	const [uploadKey, setUploadKey] = useState(null);

	const refreshPage = () => window.location.reload(true);

	const {
		errorCourtNumber,
		errorNameOfPlaintiff,
		errorAddressOfPlaintiff,
		errorLawyerOfPlaintiff,
		errorCvrOfPlaintiff,
		errorNameOfDefendant,
		errorAddressOfDefendant,
		errorLawyerOfDefendant,
		errorCvrOfDefendant,
		errorHighCourtText,
		dataAndTimeError,
		companyLogoError,
		validateForm,
	} = useFormValidation(
		selectedCourt,
		courtNumber,
		nameOfPlaintiff,
		addressOfPlaintiff,
		lawyerOfPlaintiff,
		nameOfDefendant,
		addressOfDefendant,
		lawyerOfDefendant,
		selectedStartDate
	);

	const margeDocuments = (mergeUploadKey, headers) => {
		if (!validateForm()) {
			return;
		}

		let mappedHeaderFiles = headers.map((header) => ({
			header: {
				name: header.id,
				group: header.group,
				order: header.order,
				isMandatory: header.isMandatory,
				isMultiple: header.isMultiple,
				isSortable: header.isSortable,
			},
			material: header.usedFiles.map((file) => ({
				id: file.id,
				name: header.isMultiple === true ? file.name : header.id,
				date: !header.isMultiple ? null : getUTCDateTime(file.date),
				content: file.content,
			})),
		}));

		let model = {
			path: mergeUploadKey,
			type: selectedCourt,
			caseInfo: {
				courtNo: courtNumber,
				plaintiffName: nameOfPlaintiff,
				plaintiffCVR: cvrOfPlaintiff,
				plaintiffAddress: addressOfPlaintiff,
				plaintiffsLawyer: lawyerOfPlaintiff,
				defendantName: nameOfDefendant,
				defendantAddress: addressOfDefendant,
				defendantsLawyer: lawyerOfDefendant,
				defendantCVR: cvrOfDefendant,
				trialDateStart: selectedStartDate
					? getUTCDateTime(selectedStartDate)
					: null,
				highCourtText: highCourtText,
				companyLogo: selectedImage.content,
				printBothSide: printBothSide,
				addTimeline: addTimeline,
				logoPosition: selectedLogoPosition.key,
			},
			documentation: mappedHeaderFiles,
		};
		setOverlay({ show: true, content: <LoadingPopup /> });
		margeDocumentsRequest(model, (success, response) => {
			if (success) {
				setOverlay({
					show: true,
					content: (
						<DownloadPopup
							onDownload={() => {
								window.onbeforeunload = null;
								downloadPDFFile(
									`${baseUrl}/api/v1/extract/download/${response.content}`
								);
								refreshPage();
							}}
						/>
					),
				});
			} else {
				setOverlay({
					show: true,
					content: (
						<ErrorPopup
							text={"Der opstod en fejl under oprettelse af ekstrakt"}
							subtext={response ? response : ""}
							onClose={() => {
								setOverlay({ show: false, content: <></> });
							}}
						/>
					),
				});
			}
		});
	};

	const getHeaders = (type, callback) => {
		getHeadersRequest(type, (success, response) => {
			if (success) {
				let headers = response.map((header) => ({
					id: header.name,
					group: header.group,
					order: header.order,
					usedFiles: [],
					isMultiple: header.isMultiple,
					isMandatory: header.isMandatory,
					isSortable: header.isSortable,
				}));

				callback(true, headers);
			} else {
				callback(false, null);
			}
		});
	};

	const validatePdfFile = (validateHeaders, blob, callback) => {
		validatePdfFileRequest(validateHeaders, blob, (success, response) => {
			callback(success, response);
		});
	};

	const getLogoPositions = () => {
		getLogoPositionsRequest((success, data) => {
			if (success) {
				setLogoPositions(data);

				if (extractedData) {
					setSelectedLogoPosition(
						data?.find(
							(element) => element.key === extractedData.caseInfo.logoPosition
						)
					);
				} else {
					setSelectedLogoPosition(data?.[0]);
				}
			} else {
			}
		});
	};

	useEffect(() => {
		if (currentChunkIndex !== null) {
			extractPDFFile();
		}
	}, [currentChunkIndex, uploadFile]);

	const readAndUploadCurrentChunk = (blob) => {
		var uploadHeaders = {
			"Content-Type": "application/octet-stream",
			Accept: "application/json",
			"NemEkstrakt-FileUpload-Key": uploadKey ?? null,
			"NemEkstrakt-FileUpload-FileName": uploadFile.name,
			"NemEkstrakt-FileUpload-Current": currentChunkIndex,
			"NemEkstrakt-FileUpload-Total": Math.ceil(uploadFile.size / chunkSize),
			"NemEkstrakt-FileUpload-Size": chunkSize,
		};

		if (currentChunkIndex === 0) {
			setProcessing(null);
		}

		if (currentChunkIndex === Math.ceil(uploadFile.size / chunkSize) - 1) {
			setCurrentChunkIndex(null);
			setProcessing(true);
		}

		extractDataFromPDFRequest(blob, uploadHeaders, (success, response) => {
			if (success) {
				setProcessing(null);

				if (currentChunkIndex === Math.ceil(uploadFile.size / chunkSize) - 1) {
					setCurrentChunkIndex(null);
					setUploadKey(response.path);
					setUploadSuccess(true);
					preloadHomeview(response);
				} else {
					setCurrentChunkIndex((prev) => prev + 1);
					setUploadKey(response.key);
				}
			} else {
				setUploadSuccess(false);
			}
		});
	};

	const extractPDFFile = () => {
		if (!uploadFile) {
			return;
		}
		const from = currentChunkIndex * chunkSize;
		const to = from + chunkSize;
		const blob = uploadFile.slice(from, to);
		readAndUploadCurrentChunk(blob);
	};

	const editExtractedFile = (headers) => {
		if (!validateForm()) {
			return;
		}

		let mappedHeaderFiles = headers.map((header) => ({
			header: {
				name: header.id,
				group: header.group,
				order: header.order,
				isMandatory: header.isMandatory,
				isMultiple: header.isMultiple,
				isSortable: header.isSortable,
			},
			material: header.usedFiles.map((file) => ({
				id: file.id,
				name: header.isMultiple === true ? file.name : header.id,
				date: !header.isMultiple ? null : file.date,
				content: file.content !== "" ? file.content : null,
			})),
		}));

		let model = {
			id: extractId,
			editKey: editKey,
			path: path,
			name: uploadFile.name,
			type: selectedCourt,
			caseInfo: {
				courtNo: courtNumber,
				plaintiffName: nameOfPlaintiff,
				plaintiffCVR: cvrOfPlaintiff,
				plaintiffAddress: addressOfPlaintiff,
				plaintiffsLawyer: lawyerOfPlaintiff,
				defendantName: nameOfDefendant,
				defendantAddress: addressOfDefendant,
				defendantsLawyer: lawyerOfDefendant,
				defendantCVR: cvrOfDefendant,
				trialDateStart: selectedStartDate,
				highCourtText: highCourtText,
				companyLogo: selectedImage.content,
				printBothSide: printBothSide,
				addTimeline: addTimeline,
				logoPosition: selectedLogoPosition.key,
			},
			documentation: mappedHeaderFiles,
		};

		setOverlay({ show: true, content: <LoadingPopup /> });
		editExtractedFileRequest(model, (success, response) => {
			if (success) {
				setOverlay({
					show: true,
					content: (
						<DownloadPopup
							onDownload={() => {
								window.onbeforeunload = null;
								downloadPDFFile(
									`${baseUrl}/api/v1/extract/download/${encodeURIComponent(
										response.content
									)}`
								);
								refreshPage();
							}}
						/>
					),
				});
			} else {
				setOverlay({
					show: true,
					content: (
						<ErrorPopup
							text={"Der opstod en fejl under redigering af ekstrakt"}
							subtext={response ? response : ""}
							onClose={() => {
								setOverlay({ show: false, content: <></> });
							}}
						/>
					),
				});
			}
		});
	};

	const preloadHomeview = (data) => {
		setExtractedData(data);
		setExtractId(data.id);
		setEditKey(data.editKey);
		setPath(data.path);

		setSelectedCourt(data.type);
		setCourtNumber(data.caseInfo.courtNo);
		setNameOfPlaintiff(data.caseInfo.plaintiffName);
		setCvrOfPlaintiff(data.caseInfo.plaintiffCVR);
		setAddressOfPlaintiff(data.caseInfo.plaintiffAddress);
		setLawyerOfPlaintiff(data.caseInfo.plaintiffsLawyer);
		setNameOfDefendant(data.caseInfo.defendantName);
		setAddressOfDefendant(data.caseInfo.defendantAddress);
		setLawyerOfDefendant(data.caseInfo.defendantsLawyer);
		setCvrOfDefendant(data.caseInfo.defendantCVR);
		setHighCourtText(data.caseInfo.highCourtText);

		if (data.caseInfo.trialDateStart)
			setSelectedStartDate(new Date(data.caseInfo.trialDateStart));

		if (data.caseInfo.companyLogo)
			setSelectedImage({ name: "image", content: data.caseInfo.companyLogo });
	};

	return (
		<extractContext.Provider
			value={{
				errorCourtNumber,
				errorNameOfPlaintiff,
				errorAddressOfPlaintiff,
				errorLawyerOfPlaintiff,
				errorCvrOfPlaintiff,
				errorNameOfDefendant,
				errorAddressOfDefendant,
				errorLawyerOfDefendant,
				errorHighCourtText,
				errorCvrOfDefendant,

				dataAndTimeError,
				companyLogoError,
				////
				courtNumber,
				setCourtNumber,
				nameOfPlaintiff,
				setNameOfPlaintiff,
				cvrOfPlaintiff,
				setCvrOfPlaintiff,
				addressOfPlaintiff,
				setAddressOfPlaintiff,
				lawyerOfPlaintiff,
				setLawyerOfPlaintiff,
				nameOfDefendant,
				setNameOfDefendant,
				addressOfDefendant,
				setAddressOfDefendant,
				lawyerOfDefendant,
				setLawyerOfDefendant,
				highCourtText,
				setHighCourtText,
				cvrOfDefendant,
				setCvrOfDefendant,

				////
				uploadKey,
				setUploadKey,
				chunkSize,
				uploadFile,
				setUploadFile,
				currentChunkIndex,
				setCurrentChunkIndex,
				processing,
				uploadSuccess,

				selectedLogoPosition,
				setSelectedLogoPosition,
				logoPositions,
				////
				validateForm,
				margeDocuments,
				getHeaders,
				validatePdfFile,
				getLogoPositions,
				selectedCourt,
				setSelectedCourt,
				addTimeline,
				setAddTimeline,
				selectedImage,
				setSelectedImage,
				selectedStartDate,
				setSelectedStartDate,
				extractPDFFile,
				extractedData,
				editExtractedFile,
				printBothSide,
				setPrintBothSide,
				//
				blockTypeSwitch,
				setBlockTypeSwitch,
			}}
		>
			{children}
		</extractContext.Provider>
	);
};
