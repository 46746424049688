import { useState } from "react";

export default function Section({
  title,
  defaultState = true,
  children,
  styles,
  titleFont = "text-standardXLarge",
  borderVisibleWhenCloased = true
}) {
  const [isOpen, setIsOpen] = useState(defaultState);
  return (
    <div className={`${styles}`}>
      <div
        onClick={() => setIsOpen((prev) => !prev)}
        className={`pb-[8px] text-primaryBlue ${titleFont} font-latoBold border-b-[${ isOpen || borderVisibleWhenCloased ? "1" : "0"}px] border-textLightGray flex justify-between`}
      >
        {title}
        <img
          className={`${isOpen ? "content-arrowUpIcon" : "content-arrowDownIcon"} ml-[5px]`}
          alt=""
        />
      </div>
      {isOpen && <div>{children}</div>}
    </div>
  );
}
