export default function TypeSelector({selected, setSelected, styles}) {
  const getTypeButton = (text, selected, onClick) => {
    let selectedStyle = "border-[1px] border-primaryBlue bg-primaryBlue bg-opacity-[5%]"
    let notSelectdStyle = "text-textLightGray bg-white"
    return (
      <div onClick={onClick} className={`w-full flex justify-center items-center py-[10px] select-none ${selected ? selectedStyle : notSelectdStyle}`}>
        {text}
      </div>
    );
  };

  return (
    <div className={`${styles} font-latoBold text-standard`}>
      <div className="pb-[14px] text-primaryBlue text-standardXLarge font-latoBold">
        Vælg:
      </div>
      <div className="flex gap-[12px] justify-between">
        {getTypeButton("Byret", selected === "City", () => setSelected("City"))}
        {getTypeButton("Landsret",  selected === "National", () => setSelected("National"))}
      </div>
    </div>
  );
}
