import { baseUrl, headers } from "_config";

export const margeDocumentsRequest = (model, callback) => {
	fetch(`${baseUrl}/api/v1/extract`, {
		method: "POST",
		body: JSON.stringify(model),
		headers: headers,
	})
		.then((response) => {
			if (response.status === 400) {
				response.text().then((errorMessage) => {
					callback(false, errorMessage);
					createLog("Error", `Create Extract Status:${response.status}, Error text: ${JSON.stringify(errorMessage)}`)
				});
				return;
			} else if (response.status > 400) {
				callback(false, null);
				createLog("Error", `Create Extract Status:${response.status}`)
				return;
			}

			response.json().then((json) => {
				callback(true, json);
				createLog("Information", `Documents created successfully, Response: ${JSON.stringify(json)}`)
			});
		})
		.catch((error) => {
			callback(false, null);
			createLog("Error", `Create Extract : ${error}`)
		});
};

export const getHeadersRequest = (courtType, callback) => {
	fetch(`${baseUrl}/api/v1/extract/${courtType}/headers`, {
		method: "GET",
		headers: headers,
	})
		.then((response) => response.json())
		.then((response) => {
			callback(true, response);
		})
		.catch((error) => {
			alert(`${error} getHeadersRequest`);
			callback(false, null);
			createLog("Error", `Get Headers : ${error}`)
		});
};

export const validatePdfFileRequest = (validateHeaders, blob, callback) => {
	fetch(`${baseUrl}/api/v1/extract/validate`, {
		method: "POST",
		body: blob,
		headers: validateHeaders,
	})
		.then((response) => {
			if (response.status === 400) {
				response.text().then((errorMessage) => {
					callback(false, errorMessage);
					createLog("Error", `Validate PDF File Status :${response.status}, Error text: ${JSON.stringify(errorMessage)}`)
				});
				
				return;
			} else if (response.status > 400) {
				callback(false, null);
				createLog("Error", `Validate PDF File Status :${response.status}`)
				return;
			} else if (response.status === 202) {
				response.json().then((json) => {
					json.isUploaded = false;
					callback(true, json);
				});
			} else if (response.status === 200) {
				response.json().then((json) => {
					json.isUploaded = true;
					callback(true, json);
				});
			}
		})
		.catch((error) => {
			callback(false, null);
			createLog("Error", `Validate PDF File : ${error}`)
		});
};

export const extractDataFromPDFRequest = (blob, uploadHeaders, callback) => {
	fetch(`${baseUrl}/api/v1/extract/extract`, {
		method: "POST",
		body: blob,
		headers: uploadHeaders,
	})
		.then((response) => {
			if (response.status === 400) {
				response.text().then((errorMessage) => {
					callback(false, errorMessage);
					createLog("Error", `Extract Data From PDF Status :${response.status}, Error text: ${JSON.stringify(errorMessage)}`)
				});
				return;
			} else if (response.status > 400) {
				callback(false, null);
				createLog("Error", `Extract Data From PDF Status :${response.status}`)
				return;
			} else if (response.status === 202) {
				response.json().then((json) => {
					callback(true, json);
				});
			} else if (response.status === 200) {
				response.json().then((json) => {
					callback(true, json);
				});
			}
		})
		.catch((error) => {
			callback(false, null);
			createLog("Error", `Extract Data From PDF : ${error}`)
		});
};

export const editExtractedFileRequest = (model, callback) => {
	fetch(`${baseUrl}/api/v1/extract`, {
		method: "PUT",
		body: JSON.stringify(model),
		headers: headers,
	})
		.then((response) => {
			if (response.status === 400) {
				response.text().then((errorMessage) => {
					callback(false, errorMessage);
					createLog("Error", `Edit Extracted File Status :${response.status}, Error text: ${JSON.stringify(errorMessage)}`)
				});
				return;
			} else if (response.status > 400) {
				callback(false, null);
				createLog("Error", `Edit Extracted File Status :${response.status}`)
				return;
			}

			response.json().then((json) => {
				createLog("Information", `Document edited successfully, Response: ${json}`)
				callback(true, json);
			});
		})
		.catch((error) => {
			callback(false, null);
			createLog("Error", `Edit Extracted File: ${error}`)
		});
};

export const getLogoPositionsRequest = (callback) => {
	fetch(`${baseUrl}/api/v1/extract/logo-position?language=da`, {
		method: "GET",
		headers: headers,
	})
		.then((response) => response.json())
		.then((response) => {
			if (response.status >= 400) {
				callback(false, null);
				createLog("Error", `Get Logo Positions Status :${response.status}`)
				return;
			}
			callback(true, response);
		})
		.catch((error) => {
			alert(`${error} getLogoPositionsRequest`);
			callback(false, null);
			createLog("Error", `Get Logo Positions: ${error}`)
		});
};

const createLog = (level, message) => {
	createLogRequest({level, message})
}

export const createLogRequest = (logModel) => {
	fetch(`${baseUrl}/api/v1/extract/log`, {
		method: "POST",
		headers: headers,
		body: JSON.stringify(logModel),
	}).catch(error => {})
}