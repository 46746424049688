import InputField from "__utilities/components/InputField";
import { extractContext } from "_data/context/extract";
import { useContext, useRef } from "react";

import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";

const CaseInfoForm = () => {
	const {
		errorCourtNumber,
		errorNameOfPlaintiff,
		errorAddressOfPlaintiff,
		errorLawyerOfPlaintiff,
		errorCvrOfPlaintiff,
		errorNameOfDefendant,
		errorAddressOfDefendant,
		errorLawyerOfDefendant,
		errorCvrOfDefendant,
		errorHighCourtText,
		dataAndTimeError,

		courtNumber,
		setCourtNumber,
		nameOfPlaintiff,
		setNameOfPlaintiff,
		cvrOfPlaintiff,
		setCvrOfPlaintiff,
		addressOfPlaintiff,
		setAddressOfPlaintiff,
		lawyerOfPlaintiff,
		setLawyerOfPlaintiff,
		nameOfDefendant,
		setNameOfDefendant,
		addressOfDefendant,
		setAddressOfDefendant,
		lawyerOfDefendant,
		setLawyerOfDefendant,
		highCourtText,
		setHighCourtText,
		cvrOfDefendant,
		setCvrOfDefendant,

		selectedStartDate,
		setSelectedStartDate,
		selectedCourt,
	} = useContext(extractContext);

	const startDateRef = useRef();

	return (
		<div className="flex flex-col gap-[8px] mt-[14px]">
			<InputField
				name="Rettens sagsnummer:"
				placeholder="Indtast venligst"
				value={courtNumber}
				onChange={setCourtNumber}
				error={errorCourtNumber}
			/>
			<InputField
				name={`${
					selectedCourt === "National" ? "Appellantens" : "Sagsøgers"
				} navn:`}
				placeholder={`Indtast venligst`}
				value={nameOfPlaintiff}
				onChange={setNameOfPlaintiff}
				error={errorNameOfPlaintiff}
			/>
			<InputField
				name={`${
					selectedCourt === "National" ? "Appellantens" : "Sagsøgers"
				} CVR.nr.:`}
				placeholder={`Indtast venligst`}
				value={cvrOfPlaintiff}
				onChange={setCvrOfPlaintiff}
				error={errorCvrOfPlaintiff}
			/>
			<InputField
				name={`${
					selectedCourt === "National" ? "Appellantens" : "Sagsøgers"
				} adresse:`}
				placeholder={`Indtast venligst`}
				value={addressOfPlaintiff}
				onChange={setAddressOfPlaintiff}
				error={errorAddressOfPlaintiff}
			/>
			<InputField
				name={`${
					selectedCourt === "National" ? "Appellantens" : "Sagsøgers"
				} advokat:`}
				placeholder={`Indtast venligst`}
				value={lawyerOfPlaintiff}
				onChange={setLawyerOfPlaintiff}
				error={errorLawyerOfPlaintiff}
			/>

			<InputField
				name={`${
					selectedCourt === "National" ? "Instævntes" : "Sagsøgtes"
				} navn:`}
				placeholder={`Indtast venligst`}
				value={nameOfDefendant}
				onChange={setNameOfDefendant}
				error={errorNameOfDefendant}
			/>
			<InputField
				name={`${
					selectedCourt === "National" ? "Instævntes" : "Sagsøgtes"
				} CVR.nr.:`}
				placeholder={`Indtast venligst`}
				value={cvrOfDefendant}
				onChange={setCvrOfDefendant}
				error={errorCvrOfDefendant}
			/>
			<InputField
				name={`${
					selectedCourt === "National" ? "Instævntes" : "Sagsøgtes"
				} adresse:`}
				placeholder={`Indtast venligst`}
				value={addressOfDefendant}
				onChange={setAddressOfDefendant}
				error={errorAddressOfDefendant}
			/>

			<InputField
				name={`${
					selectedCourt === "National" ? "Instævntes" : "Sagsøgtes"
				} advokat:`}
				placeholder={`Indtast venligst`}
				value={lawyerOfDefendant}
				onChange={setLawyerOfDefendant}
				error={errorLawyerOfDefendant}
			/>
			{selectedCourt === "National" && (
				<InputField
					name="Tekst i bunden af forside"
					placeholder="Indtast venligst"
					value={highCourtText}
					onChange={setHighCourtText}
					error={errorHighCourtText}
					styles="h-[80px]"
				/>
			)}
			<InputField
				name="Dato for hovedforhandling:"
				error={dataAndTimeError}
				floatingContent={
					<div>
						<DatePicker
							dateFormat="dd/MM/yyyy"
							selected={selectedStartDate}
							onChange={(date) => setSelectedStartDate(date)}
							ref={startDateRef}
						/>
					</div>
				}
				onClick={() => {
					startDateRef.current.setFocus();
				}}
			/>
		</div>
	);
};

export default CaseInfoForm;
