import UploadFile from "__utilities/components/UploadFile";
import React from "react";

const UploadPDF = React.forwardRef(({ onUploadedFile, multipleSelect = true, styles }, ref) => {
  return (
    <UploadFile onUploadedFile={onUploadedFile} ref={ref} type="application/pdf" multipleSelect={multipleSelect}>
      <div className={`w-full flex flex-col bg-lightBrown border-[1px] border-gray-400 border-dashed py-[46px] items-center cursor-pointer ${styles}`}>
        <img className="content-addFileIcon w-[27px] h-[27px]" alt="" />
        <div className="text-standard text-primaryBlue">
          <span className=" text-primaryBrown font-latoBold underline">
          Klik her for at uploade
          </span>{" "}
          eller drag and drop dine dokumenter
        </div>
      </div>
    </UploadFile>
  );
});

export default UploadPDF;
