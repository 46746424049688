import { getUuid } from "__utilities/functions/public";

export default function LoginDescription({ image, title, text }) {
  return (
    <div className="flex flex-col justify-center items-center border-b-[1px] border-b-[#F0F3F5] mb-[38px]">
      <img className={image} alt=""/>
      <div className="text-standardXLarge text-primaryBlue font-latoBold pb-[30px] pt-[8px]">{title}</div>
      <div className="w-full">
        {text?.map(element => <div key={getUuid()} className="mb-[20px] text-textGray text-standardLarge">{element}</div>)}
      </div>
    </div>
  );
}
