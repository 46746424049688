import HomepageView from "app/Homepage/HomepageView";
import Login from "./Login/LoginView";
import { ExtractProvider } from "_data/context/extract";
import { useContext } from "react";
import ScreenOverlay from "__utilities/components/ScreenOverlay";
import { overlayContext } from "_data/context/overlay";
import { useState } from "react";
import ChooseTypeView from "./ChooseTypeView";
import TermsView from "./Terms/TermsView";

export default function Main() {
  const { overlay } = useContext(overlayContext);
  const [page, setPage] = useState("Login");

  return (
    <div className="w-full h-full flex justify-center items-center">
      {page === "Login" && <Login setPage={setPage} />}
      {page === "Terms" && <TermsView setPage={setPage}/>}
      <ExtractProvider>
        {page === "Choose" && <ChooseTypeView setPage={setPage} />}
        {page === "Home" && <HomepageView />}
      </ExtractProvider>
      {overlay.show && <ScreenOverlay content={overlay.content} />}
    </div>
  );
}
