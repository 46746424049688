import Section from "__utilities/components/Section";

export default function LogoPositionDropDown({
  selectedLogoPosition,
  logoPositions,
  setSelectedLogoPosition,
}) {
  return (
    <div className="bg-white w-fit p-[10px] border-[1px] border-textLightGray">
      <Section
        defaultState={false}
        title={`Angiv logoets placering - ${selectedLogoPosition.value ?? ""}`}
        titleFont="text-standard"
        borderVisibleWhenCloased={false}
      >
        {logoPositions.map((element) => (
          <div
            key={element.key}
            onClick={() => setSelectedLogoPosition(element)}
            className={`p-[4px] ${
              element.key === selectedLogoPosition.key ? "bg-primaryBlue text-white" : ""
            }`}
          >
            {element.value}
          </div>
        ))}
      </Section>
    </div>
  );
}
