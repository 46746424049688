import CustomButton from "__utilities/components/CustomButton";
import { extractContext } from "_data/context/extract";
import { overlayContext } from "_data/context/overlay";
import ErrorPopup from "_features/ErrorPopup";
import LoadingPopup from "_features/LoadingPopup";
import UploadPDF from "_features/UploadPDF";
import { useContext, useEffect, useState } from "react";
import { useRef } from "react";

export default function ChooseTypeView({ setPage }) {
	const fileUploadRef = useRef();
	const [percentage, setPercentage] = useState(null);

	const {
		uploadFile,
		chunkSize,
		currentChunkIndex,
		processing,
		setCurrentChunkIndex,
		setBlockTypeSwitch,
		setUploadFile,
		uploadSuccess,
	} = useContext(extractContext);

	const { setOverlay } = useContext(overlayContext);

	const [showHoverText, setShowHoverText] = useState(false);

	const onExtractHandler = () => {
		if (uploadSuccess) {
			setBlockTypeSwitch(true);
			setOverlay({
				show: false,
				content: <></>,
			});
			setPage("Home");
		} else {
			setOverlay({
				show: true,
				content: (
					<ErrorPopup
						text={"Der opstod en fejl under udpakning af PDF-fil"}
						subtext={""}
						onClose={() => {
							setOverlay({ show: false, content: <></> });
						}}
					/>
				),
			});
		}
	};

	useEffect(() => {
		if (uploadSuccess !== null) {
			onExtractHandler();
		}
	}, [uploadSuccess]);

	useEffect(() => {
		if (uploadFile) {
			setPercentage(
				(
					((currentChunkIndex + 1) / Math.ceil(uploadFile.size / chunkSize)) *
					100
				).toFixed(0)
			);
			setOverlay({
				show: true,
				content: <LoadingPopup percentage={percentage} processing={processing} />,
			});
			if (currentChunkIndex === null && processing === null) {
				setOverlay({
					show: false,
					content: <></>,
				});
			} else {
			}
		}
	}, [currentChunkIndex, uploadFile, processing]);

	const onUploadedPDF = (files) => {
		setUploadFile(files[0]);
		setCurrentChunkIndex(0);
	};

	const onChangeExistingExtract = () => {
		setOverlay({
			show: true,
			content: (
				<div className="w-[33%]">
					<UploadPDF
						onUploadedFile={onUploadedPDF}
						ref={fileUploadRef}
						multipleSelect={false}
						styles="py-[78px]"
					/>
				</div>
			),
		});
	};

	const onChangeExistingExtractMouseEnter = () => {
		setShowHoverText(true);
	};

	const onChangeExistingExtractMouseLeave = () => {
		setShowHoverText(false);
	};

	return (
		<div className="w-full h-full flex justify-center items-center p-[50px]">
			<div className="w-full h-full flex flex-col justify-center items-center bg-primaryBlue bg-opacity-[6%]">
				<div className="w-1/4 flex flex-col items-center justify-center">
					<div className="text-title text-primaryBlue mb-[30px]">
						Jeg vil gerne
					</div>
					<CustomButton
						text="Oprette ny ekstrakt"
						styles="mb-[16px]"
						onClick={() => setPage("Home")}
					></CustomButton>
					<CustomButton
						text="Ændre eksisterende ekstrakt"
						onClick={onChangeExistingExtract}
						onMouseEnter={onChangeExistingExtractMouseEnter}
						onMouseLeave={onChangeExistingExtractMouseLeave}
					/>
				</div>
				<div className="h-[50px] mt-2">
					{showHoverText && (
						<span className="italic">
							(Du kan alene ændre ekstrakter med denne funktion, hvis din
							ekstrakt tidligere er genereret med NemEkstrakt)
						</span>
					)}
				</div>
			</div>
		</div>
	);
}
