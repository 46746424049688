import { extractContext } from "_data/context/extract";
import React, { useContext, useRef } from "react";
import UploadFile from "__utilities/components/UploadFile";
import { fileToBase64 } from "__utilities/functions/public";
import LogoPositionDropDown from "./LogoPositionDropDown";
import CheckBox from "__utilities/components/CheckBox";
import InputField from "__utilities/components/InputField";

function AdvancedForm() {
	const {
		companyLogoError,
		addTimeline,
		setAddTimeline,
		selectedImage,
		setSelectedImage,
		printBothSide,
		setPrintBothSide,
		selectedCourt,
		selectedLogoPosition,
		setSelectedLogoPosition,
		logoPositions,
	} = useContext(extractContext);

	const companyLogoImageRef = useRef();

	const onUploadedImage = (image) => {
		let name = image[0].name;
		fileToBase64(
			image[0],
			(error, result) => {
				if (error) return;
				let img = { name: name, content: result };
				setSelectedImage(img);
			},
			image[0].type
		);
	};

	return (
		<div className="flex flex-col gap-[8px] mt-[14px]">
			<UploadFile
				ref={companyLogoImageRef}
				onUploadedFile={onUploadedImage}
				multipleSelect={false}
				type={"image/png, image/jpeg"}
			>
				<InputField
					name="Virksomhedslogo:"
					error={companyLogoError}
					isDisabled={true}
					floatingContent={
						<div className="flex gap-[4px] text-primaryBlue">
							{selectedImage ? (
								<>
									<img
										className="content-attachedIcon"
										alt=""
									/>
									{selectedImage.name}
								</>
							) : (
								<div className="underline">Upload venligst</div>
							)}
						</div>
					}
				/>
			</UploadFile>
			<InputField
				name="Tilføj tidslinje:"
				floatingContent={
					<CheckBox
						isChacked={addTimeline}
						onChange={() => setAddTimeline((prev) => !prev)}
					/>
				}
			/>
			{selectedCourt === "National" && (
				<InputField
					name="Udskriv på begge sider af papiret:"
					floatingContent={
						<CheckBox
							isChacked={printBothSide}
							onChange={() => setPrintBothSide((prev) => !prev)}
						/>
					}
				/>
			)}
			<LogoPositionDropDown
				selectedLogoPosition={selectedLogoPosition}
				logoPositions={logoPositions}
				setSelectedLogoPosition={setSelectedLogoPosition}
			/>
		</div>
	);
}

export default AdvancedForm;
