import React from 'react'

export default function TermsSection({title, text}) {
  return (
    <div className="border-b-[1px] border-b-[#F0F3F5] mb-[20px]">
        <div className="text-standardXLarge text-primaryBlue font-latoBold">{title}</div>
        <div className="text-textGray text-standardLarge pb-[10px]">{text}</div>
    </div>
  )
}
