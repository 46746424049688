import React from "react";

const InputField = React.forwardRef(
  (
    {
      name,
      placeholder,
      error,
      isDisabled,
      value,
      onChange,
      floatingContent,
      multiline = false,
      styles,
      onClick,
    },
    ref
  ) => (
    <div
      onClick={onClick}
      className={`w-full h-[48px] flex bg-white border-[1px]  ${
        error ? "border-red-500" : "border-textLightGray"
      } ${isDisabled ? "pointer-events-none" : ""} ${styles}`}
    >
      <div className=" w-[33%] flex justify-start items-center text-standard text-primaryBlue pl-[15px] border-r-[1px] mr-[5px] my-[5px]">
        {name}
      </div>
      <div className="relative w-full h-full ">
        {multiline ? (
          <textarea
            type="text"
            className="w-full h-full absolute resize-none outline-none text-standard"
            ref={ref}
            placeholder={placeholder}
            defaultValue={value}
            onChange={(e) => {
              onChange(e.target.value);
            }}
          />
        ) : (
          <input
            type="text"
            className="w-full h-full absolute outline-none text-standard"
            ref={ref}
            placeholder={placeholder}
            defaultValue={value}
            onChange={(e) => {
              onChange(e.target.value);
            }}
          />
        )}
        {floatingContent && (
          <div className="w-full h-full absolute">
            <div className="h-full flex items-center">{floatingContent}</div>
          </div>
        )}
      </div>
    </div>
  )
);

export default InputField;
