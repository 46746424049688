import React from "react";

const background = "bg-lightBrown"
const hoverEffect = "hover:bg-primaryBrown hover:bg-opacity-[15%] cursor-pointer"

export default function IconButton({ onClick, icon, styles, hasHover, hasBackground = true, hoverText}) { 
  return (
    <div
      className={`w-[32px] h-[32px] flex justify-center items-center ${hasBackground ? background : "" } ${hasHover ? hoverEffect : ""} ${styles}`}
      title={hoverText}
      onClick={onClick}
    >
      <img className={icon} alt="" />
    </div>
  );
}
