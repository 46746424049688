import { isValidDateValue } from "@testing-library/user-event/dist/utils";
import { useRef } from "react";
import { useState } from "react";
import { Draggable } from "react-beautiful-dnd";
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
import CircularProgress from "./CircularProgress/CircularProgress";
import IconButton from "./IconButton";

export default function ListElement({
  id,
  name,
  date,
  index,
  text,
  onEdit,
  onDelete,
  styles,
  isDragDisabled,
  isEditDisabled,
  isUploaded,
  currentProgress,
  totalProgress,
  hasDate,
  error,
  errorDetails,
}) {
  const [isEditing, setIsEditing] = useState(false);
  const inputNameRef = useRef();
  const dateRef = useRef();

  const onEditName = () => {
    onEdit(inputNameRef.current.value, null);
    setIsEditing(false);
  };

  const onEditDate = (selectedDate) => {
    onEdit(null, selectedDate);
  };

  const getDateSection = () => (
    <div
      className={`flex bg-white p-[8px] justify-between items-center border-[1px] border-solid ml-[8px] ${
        error ? "border-red-500" : "border-lightGrey"
      } ${styles}`}
      onClick={() => dateRef.current.setFocus()}
    >
      <div className="mr-[5px]">
        <DatePicker
          className="w-[90px] outline-none"
          ref={dateRef}
          dateFormat="dd/MM/yyyy"
          selected={date}
          onChange={(date) => onEditDate(date)}
          placeholderText="Vælg dato"
        />
      </div>
      <div className="w-[32px] h-[32px] flex justify-center items-center bg-lightBrown cursor-pointer">
        <img className="content-calendarIcon" alt="" />
      </div>
    </div>
  );

  return (
    <Draggable draggableId={id} index={index} isDragDisabled={isDragDisabled}>
      {(provided, snapshot) => {
        return (
          <div
            ref={provided.innerRef}
            {...provided.draggableProps}
            {...provided.dragHandleProps}
            className="flex"
          >
            <div
              className={`flex w-full bg-white p-[8px] justify-between items-center border-[1px] border-solid ${
                errorDetails ? "border-red-500" : "border-lightGrey"
              } ${styles}`}
            >
              <div className="flex justify-left items-center w-full">
                {!isDragDisabled && (
                  <IconButton
                    icon={snapshot.isDragging ? "content-draggedIcon" : "content-menuIcon"}
                    styles="mr-[8px]"
                  />
                )}
                {errorDetails && (
                  <IconButton
                    icon="content-errorInfoIcon"
                    styles="mr-[8px]"
                    hasHover={false}
                    hasBackground={false}
                    hoverText={errorDetails}
                  />
                )}

                {isEditing ? (
                  <div className="w-full flex">
                    <input
                      defaultValue={name}
                      ref={inputNameRef}
                      className="w-full outline-none"
                    />
                  </div>
                ) : (
                  <span className="text-standard text-primaryBlue font-latoBold">
                    {name}
                  </span>
                )}
                {text && (
                  <div className="ml-[10px] pl-[10px] border-textLightGray text-textLightGray border-l-[1px]">
                    {text}
                  </div>
                )}
              </div>

              <div className="flex">
                {!isUploaded && !errorDetails && (
                  <CircularProgress progress={currentProgress} total={totalProgress} />
                )}
                {isUploaded && !isEditDisabled && (
                  <IconButton
                    onClick={
                      isEditing
                        ? () => onEditName()
                        : () => {
                            setIsEditing(true);
                            setTimeout(() => inputNameRef.current.focus(), 100);
                          }
                    }
                    icon={isEditing ? "content-checkmarkIcon" : "content-editIcon"}
                    styles="mr-[8px]"
                    hasHover={true}
                  />
                )}
                {(isUploaded || errorDetails) && (
                  <IconButton
                    icon="content-trashIcon"
                    onClick={onDelete}
                    hasHover={true}
                  />
                )}
              </div>
            </div>
            {hasDate && getDateSection()}
          </div>
        );
      }}
    </Draggable>
  );
}
