import CustomButton from "__utilities/components/CustomButton";
import LoginDescription from "app/Login/PageComponents/LoginDescription";

export default function Login({ setPage }) {
  return (
    <div className="w-full h-full flex justify-center items-center py-[40px] px-[34px] bg-primaryBlue bg-opacity-[6%]">
      <div className="w-full h-full flex flex-col items-center justify-center">
        <div className="flex flex-col items-center justify-center h-full">
          <div className="tall:h-[50%] pt-[30%]">
            <img className="content-logoIcon w-[170px]" alt="" />
          </div>
          <div className="tall:h-[50%]">
            <div className="text-title font-latoBold text-primaryBlue pb-[10px]">
              Velkommen!
            </div>
            <CustomButton text="Fortsæt" onClick={() => setPage("Choose")} />
          </div>
          <div className="text-primaryBlue mt-[10px]">
            Ved at klikke “Fortsæt” accepterer du samtidigt vores
            forretningsbetingelser og ansvarsfraskrivelse, som du finder{" "}
            <span className="font-latoBold underline cursor-pointer" onClick={() => setPage("Terms")}>her.</span>
          </div>
        </div>
      </div>
      <div className="w-full h-full bg-white flex flex-col items-center justify-center">
        <div className="w-full overflow-auto p-[35px]">
          <LoginDescription
            image="content-clockIcon"
            title="Ekstrakter på under 15 minutter!"
            text={[
              "Med NemEkstrakt får du adgang til et værktøj målrettet advokatbranchen til udarbejdelse af ekstrakter.",
              "Med en kompromisløs tilgang til brugervenlighed, sikkerhed og effektivitet har vi skabt et værktøj, som hvert år kan spare selv mindre advokatkontorer for mange timer.",
            ]}
          />
          <LoginDescription
            image="content-documentIcon"
            title="Hvordan virker det?"
            text={[
              "Indledningsvist skal du via minretssag.dk downloade de dokumenter, som du ønsker at medtage i din ekstrakt.",
              "Herefter vil NemEkstrakt intuitivt lade dig oprette og downloade dit ekstrakt inkl. forside, sidenummerering og indholdsfortegnelse jf. kravene ift. byret og landsret.",
              "NemEkstrakt gemmer ingen dokumenter eller data vedr. sagen. Alle dine data ligger gemt i PDF’en, som du efterfølgende kan bruge NemEkstrakt til at redigere indtil ekstraktet er endeligt aftalt mellem begge parter.",
              <>
                NemEkstrakt giver dig{" "}
                <span className="underline">maksimal effektivitet</span> med{" "}
                <span className="underline">maksimal sikkerhed!</span>
              </>,
            ]}
          />
          <LoginDescription
            title="Gratis!"
            text={[
              "NemEkstrakt.dk er en gratis service.",
              <>
                <span className="italic">
                  Vi vil meget gerne høre, hvad du synes om løsningen.{" "}
                  <a
                    href="mailto:al@ditadvokathus.dk"
                    className="text-primaryBlue underline"
                  >
                    Skriv til os her!
                  </a>
                </span>
              </>,
            ]}
          />
        </div>
      </div>
    </div>
  );
}
