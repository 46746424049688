import CustomButton from "__utilities/components/CustomButton";
import Section from "__utilities/components/Section";
import AdvancedForm from "_features/AdvancedForm";
import CaseInfoForm from "_features/CaseInfoForm";
import DroppableItem from "_features/DroppableItem";
import DroppableList from "_features/DroppableList";
import TypeSelector from "_features/TypeSelector";
import React, { useState } from "react";

export default function ExtractContent({
	selectedCourt,
	setSelectedCourt,
	headers,
	onSortClick,
	onItemDelete,
	onItemEdit,
	editExtractedFile,
	margeDocuments,
	mergeUploadKey,
	editMode,
	blockTypeSwitch,
	validateDates,
	validateRequiredItems,
	validateMandatoryHeaders,
	validateForm,
}) {
	const [showFileErrors, setShowFileErrors] = useState(false);

	return (
		<div className="px-[40px] py-[30px]">
			<TypeSelector
				selected={selectedCourt}
				setSelected={setSelectedCourt}
				styles={`mb-[28px] ${blockTypeSwitch ? "pointer-events-none" : ""}`}
			/>
			<Section
				title="Sagsoplysninger:"
				defaultState={false}
				styles="mb-[30px]"
			>
				<CaseInfoForm />
			</Section>
			<Section
				title="Layout"
				defaultState={false}
				styles="mb-[30px]"
			>
				<AdvancedForm />
			</Section>
			<div className="mb-[16px] text-primaryBlue text-standardXLarge font-latoBold">
				Dokumentation:
			</div>

			<div className="mb-[30px]">
				{headers.map((header) => {
					if (!header.isMultiple) {
						return (
							<DroppableItem
								key={header.id}
								droppableId={header.id}
								item={{
									id: header.usedFiles?.[0]?.id,
									name: header.id,
									index: 0,
									fileName: header.name,
								}}
								hasPlaceholder={true}
								isSelected={
									header.usedFiles?.[0]?.content !== null &&
									header.usedFiles?.[0]?.content !== undefined
										? true
										: false
								}
								onItemDelete={onItemDelete}
								error={
									(header.usedFiles?.[0]?.content === null ||
										header.usedFiles?.[0]?.content === undefined) &&
									showFileErrors
										? true
										: false
								}
							/>
						);
					} else {
						return (
							<DroppableList
								key={header.id}
								header={header.id}
								isSortable={header.isSortable}
								onSortClick={onSortClick}
								droppableId={header.id}
								fileList={header.usedFiles}
								styles={`mb-[12px]`}
								hasPlaceholder={true}
								onItemDelete={onItemDelete}
								onItemEdit={onItemEdit}
								elementsHasError={true}
								listHasError={
									!header.required &&
									header.isMandatory &&
									((header?.usedFiles?.length ?? 0) === 0 ||
										(header?.usedFiles?.length > 0 &&
											header?.usedFiles?.find(
												(f) => f.content === null || f.content === undefined
											))) &&
									showFileErrors
										? true
										: false
								}
								listHasDate={true}
							/>
						);
					}
				})}
			</div>
			<CustomButton
				text="OPRET EKSTRAKT"
				styles="text-standardSmall"
				onClick={() => {
					setShowFileErrors(true);
					let isValid = validateForm();
					isValid &= validateDates();
					isValid &= validateRequiredItems();
					isValid &= validateMandatoryHeaders();
					if (!isValid) return;

					if (editMode) {
						editExtractedFile(headers);
					} else {
						margeDocuments(mergeUploadKey, headers);
					}
				}}
			/>
		</div>
	);
}
