import { fileToBase64, getUuid } from "__utilities/functions/public";
import { extractContext } from "_data/context/extract";
import DroppableList from "_features/DroppableList";
import UploadPDF from "_features/UploadPDF";
import Header from "app/Homepage/PageComponents/Header";
import { useContext, useEffect, useRef, useState } from "react";
import { DragDropContext } from "react-beautiful-dnd";
import ExtractContent from "./PageComponents/ExtractContent";

export default function HomepageView() {
	const uploadFileRef = useRef();

	const [uploadedFiles, setUploadedFiles] = useState([]);
	const [filesToUpload, setFilesToUpload] = useState([]);

	const [editMode, setEditMode] = useState(false);

	const chunkSize = 10 * 1024 * 1024;

	const {
		margeDocuments,
		getHeaders,
		validatePdfFile,
		selectedCourt,
		setSelectedCourt,
		extractedData,
		editExtractedFile,
		blockTypeSwitch,
		getLogoPositions,
		validateForm,
		uploadKey,
		setUploadKey,
	} = useContext(extractContext);

	const [headers, setHeaders] = useState([]);

	const onUploadedFile = (files) => {
		let filesToUpload = [];
		for (let i = 0; i < files.length; i++) {
			if (files[i].type !== "application/pdf") {
				continue;
			}

			let name = files[i].name;

			let file = {
				id: getUuid(),
				name: name,
				content: null,
				date: "",
				isValid: null,
				isUploaded: false,
				currentChunkIndex: 0,
				totalChunks: 0,
			};

			setUploadedFiles((prev) => [...prev, file]);
			filesToUpload.push({ id: file.id, file: files[i] });
		}

		if (filesToUpload.length > 0) {
			setFilesToUpload(filesToUpload);
		}
	};

	useEffect(() => {
		const files = [...filesToUpload];

		if (files.length > 0) {
			OnUploadFiles(uploadKey, files[0], 0, files.slice(1, files.length));
		}
	}, [filesToUpload]);

	const OnUploadFiles = (
		localUploadKey,
		fileToUpload,
		currentChunkIndex,
		remainingFiles
	) => {
		const from = currentChunkIndex * chunkSize;
		const to = from + chunkSize;
		const blob = fileToUpload.file.slice(from, to);

		let validateHeaders = {
			"Content-Type": "application/octet-stream",
			Accept: "application/json",
			"NemEkstrakt-FileUpload-Key": localUploadKey ?? null,
			"NemEkstrakt-FileUpload-FileName": encodeURIComponent(
				`${fileToUpload.id}_${fileToUpload.file.name}`
			),
			"NemEkstrakt-FileUpload-Current": currentChunkIndex,
			"NemEkstrakt-FileUpload-Total": Math.ceil(
				fileToUpload.file.size / chunkSize
			),
			"NemEkstrakt-FileUpload-Size": chunkSize,
		};

		validatePdfFile(validateHeaders, blob, (success, response) => {
			let file = uploadedFiles.find((f) => f.id === fileToUpload.id);

			if (success) {
				file.content = response.content;
				setUploadKey(uploadKey === null ? response.key : uploadKey);

				if (response.isUploaded !== true) {
					OnUploadFiles(response.key, fileToUpload, currentChunkIndex + 1, []);
				}

				for (let i = 0; i < remainingFiles.length; i++) {
					OnUploadFiles(response.key, remainingFiles[i], 0, []);
				}
			} else {
				if (remainingFiles.length > 0) {
					OnUploadFiles(
						localUploadKey,
						remainingFiles[0],
						0,
						remainingFiles.slice(1, remainingFiles.length)
					);
				}
			}

			file.currentChunkIndex = currentChunkIndex;
			file.totalChunks = Math.ceil(fileToUpload.file.size / chunkSize);

			file.isUploaded = success === true && response.isUploaded === true;
			file.isValid = success;
			file.error = success ? null : "Fejl ved læsning af PDF-fil";

			setUploadedFiles((prev) =>
				prev.map((f) => {
					if (f.id === fileToUpload.id) {
						return file;
					} else {
						return f;
					}
				})
			);
		});
	};

	const validateDates = () => {
		let isValid = true;
		headers.forEach((header) => {
			if (header.isMultiple) {
				header?.usedFiles?.forEach((file) => {
					if (!file.date) {
						isValid = false;
					}
				});
			}
		});
		return isValid;
	};

	const validateRequiredItems = () => {
		let isValid = true;
		headers.forEach((header) => {
			if (header.required) {
				header.required.forEach((item) => {
					if (
						item.content !== null && item.content !== undefined ? false : true
					) {
						isValid = false;
					}
				});
			}
		});
		return isValid;
	};

	const validateMandatoryHeaders = () => {
		let isValid = true;
		headers.forEach((header) => {
			if (!header.isMultiple) {
				if (
					header.usedFiles?.[0]?.content === null ||
					header.usedFiles?.[0]?.content === undefined
				) {
					isValid = false;
				}
			} else {
				if (
					!header.required &&
					header.isMandatory &&
					((header?.usedFiles?.length ?? 0) === 0 ||
						(header?.usedFiles?.length > 0 &&
							header?.usedFiles?.find(
								(f) => f.content === null || f.content === undefined
							)))
				) {
					isValid = false;
				}
			}
		});
		return isValid;
	};

	const onItemEdit = (id, value, date) => {
		//unused
		let unusedFilesArray = uploadedFiles;
		const containsUnusedFile = unusedFilesArray.find((item) => item.id === id);
		if (containsUnusedFile) {
			unusedFilesArray = unusedFilesArray.map((item) => ({
				...item,
				name: item.id === id && value !== null ? value : item.name,
				date: item.id === id && date !== null ? date : item.date,
			}));
			setUploadedFiles(unusedFilesArray);
			return;
		}
		// used
		let usedFilesArray = headers;
		const containsUsedFile = usedFilesArray.find((header) =>
			header.usedFiles.find((item) => item.id === id)
		);
		if (containsUsedFile) {
			usedFilesArray = usedFilesArray.map((header) => ({
				...header,
				usedFiles: header.usedFiles.map((item) => ({
					...item,
					name: item.id === id && value !== null ? value : item.name,
					date: item.id === id && date !== null ? date : item.date,
				})),
			}));
			setHeaders(usedFilesArray);
			return;
		}
	};

	const onSortClick = (name) => {
		let usedFilesArray = headers;
		const containsUsedFile = usedFilesArray.find(
			(header) => header.id === name
		);
		if (containsUsedFile) {
			usedFilesArray = usedFilesArray.map((header) => ({
				...header,
				usedFiles: sortArrayByDate(header.usedFiles, header.id === name),
			}));
			setHeaders(usedFilesArray);
			return;
		}
	};

	const sortArrayByDate = (array, canSort) => {
		if (canSort && array?.length > 0) {
			return array.sort((objA, objB) => Number(objB.date) - Number(objA.date));
		} else {
			return array;
		}
	};

	const onItemDelete = (id) => {
		//unused
		let unusedFilesArray = uploadedFiles;
		const containsUnusedFile = unusedFilesArray.find((item) => item.id === id);
		if (containsUnusedFile) {
			unusedFilesArray = unusedFilesArray.filter((item) => item.id !== id);
			setUploadedFiles(unusedFilesArray);
			return;
		}
		// used
		let usedFilesArray = headers;
		const containsUsedFile = usedFilesArray.find((header) =>
			header.usedFiles.find((item) => item.id === id)
		);
		if (containsUsedFile) {
			usedFilesArray = usedFilesArray.map((header) => ({
				...header,
				usedFiles: header.usedFiles.filter((item) => item.id !== id),
			}));
			setHeaders(usedFilesArray);
			return;
		}
	};

	const onDragEnd = (result) => {
		const { destination, source } = result;

		if (!destination) return;
		if (
			destination.droppableId === source.droppableId &&
			destination.index === source.index
		)
			return;

		let add;
		let unusedFilesArray = uploadedFiles;
		let usedFilesArray = headers;
		if (source.droppableId === "UploadedFileList") {
			add = unusedFilesArray[source.index];
			unusedFilesArray.splice(source.index, 1);
		} else {
			for (let i = 0; i < usedFilesArray.length; i++) {
				if (usedFilesArray[i].id === source.droppableId) {
					add = usedFilesArray[i].usedFiles[source.index];
					usedFilesArray[i].usedFiles.splice(source.index, 1);
				}
			}
		}

		if (destination.droppableId === "UploadedFileList") {
			unusedFilesArray.splice(destination.index, 0, add);
		} else {
			for (let i = 0; i < usedFilesArray.length; i++) {
				if (usedFilesArray[i].id === destination.droppableId) {
					if (
						!usedFilesArray[i].isMultiple &&
						usedFilesArray[i].usedFiles.length > 0
					)
						return;
					usedFilesArray[i].usedFiles.splice(destination.index, 0, add);
				}
			}
		}

		setUploadedFiles([...unusedFilesArray]);
	};

	const loadExtractedData = (data) => {
		let extHeaders = data.documentation.map((header) => ({
			id: header.header.name,
			order: header.header.order,
			usedFiles: header.material.map((file) => ({
				...file,
				isValid: true,
				isUploaded: true,
				date: new Date(file.date),
				content: "",
			})),
			isMandatory: header.header.isMandatory,
			isMultiple: header.header.isMultiple,
			isSortable: header.header.isSortable,
		}));
		setHeaders(extHeaders);
	};

	useEffect(() => {
		window.onbeforeunload = () => {
			return "Are you sure you want to leave?";
		};

		getLogoPositions();
		if (extractedData) {
			//preloading exptracted data from context
			loadExtractedData(extractedData);
			setEditMode(true);
			return;
		}

		getHeaders(selectedCourt, (success, headers) => {
			if (success) {
				setHeaders(headers);
			}
		});
	}, [selectedCourt]);

	return (
		<div className="w-full h-full flex flex-col px-[40px]">
			<Header />
			<div className="w-full  flex mt-[30px] overflow-hidden">
				<DragDropContext onDragEnd={onDragEnd}>
					<div className="w-full h-full">
						<div className="text-primaryBlue text-standardXLarge font-latoBold mb-[16px]">
							Alle dokumenter
						</div>
						<UploadPDF
							ref={uploadFileRef}
							onUploadedFile={onUploadedFile}
						/>
						<div className="h-[calc(100vh-310px)] overflow-auto">
							<DroppableList
								droppableId="UploadedFileList"
								fileList={uploadedFiles}
								onItemDelete={onItemDelete}
								onItemEdit={onItemEdit}
							/>
						</div>
					</div>

					<div className="w-full h-full ml-[40px]">
						<div className="mb-[16px] text-primaryBlue text-standardXLarge font-latoBold">
							Din ekstrakt{" "}
							<span className="font-latoLight text-[#A0A0A0]">
								(træk dine dokumenter herover, når du har uploadet)
							</span>
						</div>
						<div className="h-[calc(100vh-170px)] overflow-auto bg-primaryBlue bg-opacity-[6%]">
							<ExtractContent
								selectedCourt={selectedCourt}
								setSelectedCourt={setSelectedCourt}
								headers={headers}
								onSortClick={onSortClick}
								onItemDelete={onItemDelete}
								onItemEdit={onItemEdit}
								editExtractedFile={editExtractedFile}
								margeDocuments={margeDocuments}
								mergeUploadKey={uploadKey}
								editMode={editMode}
								blockTypeSwitch={blockTypeSwitch}
								validateDates={validateDates}
								validateRequiredItems={validateRequiredItems}
								validateMandatoryHeaders={validateMandatoryHeaders}
								validateForm={validateForm}
							/>
						</div>
					</div>
				</DragDropContext>
			</div>
		</div>
	);
}
