import React from "react";
import ReactDOM from "react-dom/client";
import "index.css";
import Main from "app/MainView";
import { OverlayProvider } from "_data/context/overlay";

const root = ReactDOM.createRoot(document.getElementById("root"));
root.render(
  // <React.StrictMode>
  <OverlayProvider>
    <Main />
  </OverlayProvider>
  // </React.StrictMode>
);
